import React from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { H1, H2, Paragraph, ContentContainer, ExternalLink } from "../components/ds"

const Page = () => {
  return (
    <>
      <SEO title="Övrig information" />
      <Layout>
        <ContentContainer>
          <H1>Övrig information</H1>
          <H2>Försäkringar</H2>
          <Paragraph>Barn som är inskrivna i en förskola med enskild huvudman omfattas av kommunens olycksfalls- och ansvarsförsäkring. Information om kommunens skolförsäkring och blanketter för olycksfallsanmälan finns att hämta på <ExternalLink href="http://www.ornskoldsvik.se/utbildningochbarnomsorg/forsakringar.4.6ded17ba1371f850d9f85f.html">denna länk</ExternalLink></Paragraph>
        </ContentContainer>
      </Layout>
    </>
  )
}



export default Page
